<script>
import Vue from "vue";
import GlobalVue from "@helper/Global.vue";
import moment from "moment";
import Gen from "@helper/Gen";
import FOGen from "@helper/FOGen";
import Croppa from "vue-croppa";
import swal from "sweetalert";
import VTooltip from "v-tooltip";
import { VueMasonryPlugin } from "vue-masonry";
import VueSocialSharing from "vue-social-sharing";
import NProgress from "vue-nprogress";
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  max,
  min,
  image,
  numeric,
  alpha,
  alpha_num,
  alpha_spaces,
} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("alpha_spaces", {
  ...alpha_spaces,
  message: "This field may contain alphabetic characters or spaces",
});

extend("alpha_num", {
  ...alpha_num,
  message:
    "The field under validation may contain alphabetic characters or numbers.",
});

extend("max", {
  ...max,
  message: "This field may not be greater than {length} character",
});

extend("min", {
  ...min,
  message: "This field must be at least {length} character",
});

extend("numeric", {
  ...numeric,
  message: "This field must only consist of numbers.",
});

extend("email", {
  ...email,
  message: "This field is not a valid email",
});

extend("alpha", {
  ...alpha,
  message: "This field must be alpha",
});

extend("image", {
  ...image,
  message: "This field allowed only image",
});

/* Vue plugins */
Vue.use(VueMasonryPlugin);
Vue.use(Croppa);
Vue.use(VueSocialSharing);
Vue.use(VTooltip);
Vue.use(NprogressContainer);
VTooltip.enabled = window.innerWidth > 767;

Vue.use(NProgress);

moment.locale("en");
global.moment = moment;

/* CSS */
import "@frontend/css/custom.scss";
import "@frontend/css/responsive.scss";

/* JS */
import "@plugins/jquery/dist/jquery.min.js";
import "@plugins/jquery-validation/jquery-validation.js";
import "@helper/general_function.js";
import "@plugins/extension.js";
import "@frontend/js/main.js";

/* Vue Component */
Vue.component("ImagePreview", () =>
  import("@backend/components/ImagePreview.vue")
);
Vue.component("VInfo", () => import("@frontend/components/VInfo.vue"));
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

global.$ = global.jQuery;
global.Gen = Gen;
global.App.user = null;

export default {
  name: "FoMain",
  extends: GlobalVue,
  async mounted() {
    window.history.scrollRestoration = "manual";
    global.FOGen = FOGen;

    /* Load Variable */
    this.refreshVariableFo();

    Gen.loadCss("/frontend/css/bootstrap.css");
    Gen.loadCss("/frontend/css/style.min.css");
    Gen.loadCss("/frontend/css/swiper.css");
    Gen.loadCss("/frontend/css/dark.css");
    Gen.loadCss("/frontend/css/font-icons.css");
    Gen.loadCss("/frontend/css/animate.css");
    Gen.loadCss("/frontend/css/magnific-popup.css");
    Gen.loadCss("/frontend/css/colors.css");
    Gen.loadCss("/frontend/css/custom_l9.css?v=0.14.1");
    Gen.loadCss("/frontend/css/responsive_l9.css");

    await Gen.loadScript("/frontend/js/plugins.min.js");
    await Gen.loadScript("/frontend/js/functions.min.js?v=2");
    await Gen.loadScript("/frontend/js/custom.js?v=0.1");
    global.$ = global.jQuery;

    /* Ready Loaded */
    $(document).ready(() => {
      this.$root.ready = true;
    });

    var self = this;
    $(document).on("click", "a", function(e) {
      if ($(this).is("[reload]")) return;
      if ($(this).is("[target]")) return;
      if ($(this).attr("data-lightbox")) return;
      if ($(this).attr("href") == "javascript:;") return;
      if ($(this).hasClass('view-lightbox')) return;
      if ($(this).attr("href")) {
        if ($(this).attr("href")[0] == "#") return;

        if (
          $(this)
            .attr("href")
            .indexOf(location.origin) > -1
        ) {
          e.preventDefault();
          self.$router.push(
            $(this)
              .attr("href")
              .replace(location.origin, "")
          );
        }
        if (
          $(this)
            .attr("href")
            .indexOf(":") < 0
        ) {
          e.preventDefault();
          if (self.$route.fullPath != $(this).attr("href"))
            self.$router.push($(this).attr("href"));
        }
      }
    });

    $(document).on("keydown", ".form-control", function(e) {
      if (e.which === 32 && e.target.selectionStart === 0) {
        return false;
      }
    });

    this.$root.topProgress = this.$refs.topProgress;
    setTimeout(() => {
      $("#footer").css("margin-top", "0");
    }, 2000);
    $(document).on("click", ".pagination a", (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
  },
  watch: {
    $route() {
      setTimeout(() => {
        SEMICOLON.header.init();
      }, 500);
    },
  },
};

$(document).scroll(function() {
  var y = $(this).scrollTop();
  if (y > 200) $(".bottom_navigation").fadeIn();
  else $(".bottom_navigation").fadeOut();
});
</script>

<template>
  <div id="FoMain">
    <router-view />
  </div>
</template>
