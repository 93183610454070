import Config from "../config/Config";
import Gen from "@helper/Gen";
import env from "../env";

const FOGen = {
  _static_content: false,
  staticContent(cb) {
    if (this._static_content) return cb(this._static_content);
    if (this._static_content === false) {
      this._static_content = null;
      return Gen.apirest("/static-content", {}, (err, resp) => {
        if (err) return;
        this._static_content = resp.data;
        cb(resp.data);
      });
    }
    let it = setInterval(() => {
      if (this._static_content) {
        cb(this._static_content);
        clearInterval(it);
      }
    }, 100);
  },
  queryToObject(str) {
    if (str.split("?").length < 2) return {};
    var query = str
      .replace("?", "")
      .replace(/\+/g, " ")
      .split("&");
    query = query
      .map((a) => {
        return (a += a.indexOf("=") == -1 ? "=" : "");
      })
      .join("&");
    if (!query) return {};
    return JSON.parse(
      '{"' +
        decodeURIComponent(query)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  },
  objectToQuery(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  },
  toObject(form) {
    var data = {};
    form.serializeArray().forEach((v) => {
      if (v.name.indexOf("[]") > -1) {
        if (!data[v.name.replace("[]", "")])
          return (data[v.name.replace("[]", "")] = [v.value]);
        return data[v.name.replace("[]", "")].push(v.value);
      }
      data[v.name] = v.value;
    });
    return data;
  },
  rest(opt = {}) {
    if (typeof arguments[0] != "object") {
      opt = {
        url: arguments[0],
        data: arguments[1],
        success: arguments[2],
        type: arguments[3] || "get",
      };
    }
    if (opt.url.indexOf("http") == -1) opt.url = env.baseUrl + opt.url;
    var addQuery = Object.assign(this.queryToObject(opt.url));
    opt.type = opt.type.toUpperCase();
    if (opt.type == "GET") {
      opt.data = Object.assign(addQuery, opt.data);
    } else {
      opt.url += "?" + this.objectToQuery(addQuery);
      opt.data = JSON.stringify(opt.data);
      opt.contentType = "application/json; charset=utf-8";
      opt.dataType = "json";
    }
    opt.success = (resp) => {
      arguments[2](null, resp);
    };
    opt.error = (err) => {
      err.resp = err.responseJSON;
      arguments[2](err, null);
    };
    return $.ajax(opt);
  },
  apirest() {
    var args = arguments;
    if (args[0].indexOf("http") == -1) args[0] = Config.apiUrl + args[0];
    return this.rest(args[0], args[1], args[2], args[3]);
  },
};

export default FOGen;
